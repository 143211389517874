import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "./Header.css";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  RecaptchaVerifier,
  EmailAuthProvider,
  linkWithCredential,
  signInWithPhoneNumber,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "./Firebase.js";
import { getDefaultNormalizer } from "@testing-library/react";
import Main from "./Main.js";

function Header({ ImageUrl }) {
  const navigate = useNavigate();

  const [batsu, setBatsu] = useState(true);
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [login, setLogin] = useState(false);
  const [help, setHelp] = useState(false);
  const [registerphone, setRegisterPhone] = useState("+81");
  const [pressed, setPressed] = useState(true);
  const [registerCode, setRegisterCode] = useState("");
  const [currentpic, setCurrentpic] = useState([""]);
  const [id, setId] = useState("");
  const [fakeconnecton, setFakeConnectOn] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        const userId = currentUser.uid;
        const userDocumentRef = doc(db, "users", userId);
        await getDoc(userDocumentRef).then((documentSnapshot) => {
          documentSnapshot.data();
          setCurrentpic(documentSnapshot.data().Profile_image);
        });
        setId(currentUser.uid);
      }
    });
  }, []);

  const round = () => {
    setLogin(false);
    setHelp(false);
  };

  const reset = () => {
    setHelp(true);
  };

  const appear = () => {
    setLogin(!login);
  };

  const Login = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password).then((value) => {
        navigate(`/User/${value.user.uid}`);
      });
    } catch (error) {
      alert("メールアドレスまたはパスワードが間違っています");
    }
  };

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {},
      },
      auth
    );
  };

  const requestOTP = (e) => {
    e.preventDefault();
    generateRecaptcha();
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, registerphone, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setPressed(!pressed);
      })
      .catch((error) => {
        alert("電話番号が正しくありません");
        window.location.reload(false);
      });
  };

  const SendOTP = (e) => {
    e.preventDefault();
    let confirmationResult = window.confirmationResult;
    confirmationResult
      .confirm(registerCode)
      .then((result) => {
        const user = result.user;
        alert("パスワード再発行のメールを送りました");
        let reemail = user.email;
        sendPasswordResetEmail(auth, reemail).then(() => {});
      })
      .catch((error) => {
        alert("コードが違います");
      });
    {
      setTimeout(out, 2000);
    }
  };

  const out = async () => {
    await signOut(auth);
    window.location.reload(false);
  };

  const TurnFakeConnectOn = () => {
    setFakeConnectOn(true);
  };

  return (
    <div
      style={{
        // overscrollBehaviorBlock: "none",
        // overscrollBehaviorInline: "none",
        //overscrollBehaviorY: "none",
        //overflow: "scroll",
        //touchAction: "none",

        //touchAction should not have restrictions when fakeconnect is there
        //need to capture that state from parent to child
        touchAction: !fakeconnecton && "pinch-zoom",
      }}
      className="Header"
    >
      <div
        style={{
          width: "100%",
          height: 66,
          display: "block",
          background: "rgb(18, 143, 200)",
          //background: "linear-gradient(#2f17e7, rgb(26, 155, 214)",
          opacity: 0.68,
          zIndex: 1,
          //position absolute on position fixed off when in google 審査
          //position: "fixed", //could turn back on after google ad sense
          // position: "absolute",
          // top: 0,
        }}
      ></div>
      <img className="Logo" src="./Logo1.svg" />
      <div className="Title_Content">
        <img
          className="Title__Help"
          src={batsu ? "./HelpNew2.svg" : "./Tog1.svg"}
          onClick={(e) => setBatsu(!batsu)}
        />
        {!user ? (
          <span className="Right">
            <span className="Signupdash">/</span>
            <Link
              className="Sign-up"
              style={{ textDecoration: "none" }}
              to={`/SignUp`}
            >
              登録
            </Link>
            <span
              style={{ marginRight: 1, marginLeft: 2 }}
              className="Signupdash"
            >
              /
            </span>
            {!login ? (
              <span className="Sign-up" onClick={appear}>
                ログイン
              </span>
            ) : (
              <img
                className="toggle1"
                onClick={!help ? appear : round}
                src="./Tog1.svg"
              />
            )}
            <span className="Signupdash">/</span>
            <img className="Flag" src="./Flag1.svg" />
          </span>
        ) : (
          <span className="Right">
            <Link to={`/User/${id}`}>
              <img className="current5" src={currentpic} />
            </Link>

            <img className="Flag" src="./Flag1.svg" />
          </span>
        )}
      </div>

      {login && (
        <div>
          {!help ? (
            <>
              <form className="LogPanel" onSubmit={Login}>
                <input
                  className="email"
                  type="email"
                  placeholder="メールアドレス"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  className="password"
                  placeholder="パスワード"
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ flex: 0.95, marginLeft: 20 }}>
                    <button
                      //type="submit" altnertaively
                      submit="Login"
                      className="Loginbutton"
                      onClick={Login}
                    >
                      ログイン
                    </button>
                  </span>
                  <img
                    className="Title__Help2"
                    onClick={reset}
                    src="./HelpNew2.svg"
                  />
                </span>
              </form>
            </>
          ) : (
            <>
              <form className="reset" onSubmit={requestOTP}>
                <div className="reset_text">パスワードを忘れましたか？</div>
                <img className="phone1" src="./Phone3.svg" />
                {pressed ? (
                  <>
                    <input
                      className="Send4"
                      name="tel"
                      type="tel"
                      placeholder="電話番号"
                      value={registerphone}
                      onChange={(e) => setRegisterPhone(e.target.value)}
                    />
                    <button className="code2" type="submit" id="sign-in-button">
                      送信
                    </button>
                  </>
                ) : (
                  <>
                    <input
                      className="Send5"
                      name="number"
                      type="number"
                      placeholder="コード"
                      value={registerCode}
                      onChange={(e) => setRegisterCode(e.target.value)}
                    />
                    <button className="code2" onClick={SendOTP}>
                      送信コード
                    </button>
                  </>
                )}
              </form>
            </>
          )}
        </div>
      )}

      {!batsu && (
        <>
          <div className="helpside">
            <img className="CM" src="./CM1.svg" />
            <img className="Work" src="./Workapply.svg" />
          </div>
        </>
      )}
      <Main TurnFakeConnectOn={TurnFakeConnectOn} />
    </div>
  );
}
export default Header;
